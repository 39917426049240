import RestResource from '../rest.service';
export default class SimpleNegotiationsService extends RestResource {
  map(payload) {
    const observations = payload.configurations ? payload.configurations.observations : payload.observations;
    return {
      observations: observations,
      user_id: payload.producerId,
      client_id: payload.clientId,
      type: payload.type,
      state: payload.state
    };
  }

  constructor(api) {
    super(api, 'simple-negotiations', 'api');
  }
}
